import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { concat, Observable, of, Subject } from 'rxjs';
import { MiscService, ChoiceGroupService, VisitorService, VisitorPsychoEntryService } from '@services/index';
import { Globals } from '@app/app.globals';

@Component({
    selector: 'app-util-visitor-parts-psycho-entry-modals-add',
    templateUrl: './add.component.html',
})
export class UtilVisitorPartsPsychoEntryModalsAddComponent implements OnInit {
    @Output() addPsychoEntryEvent = new EventEmitter<any>();

    isLoading = false;
    isSubmitting = false;
    wrongDate = false;
    modal = "#addPsychoEntryModal";
    cud = "add";
    pickerConfig: any;
    visitorPsychoEntry: any;
    visitorId: number;
    visitors: Observable<any[]>;
    visitorsLoading = false;
    visitorsInput = new Subject();
    forbiddenDates = [];
    choiceGroups: any;
    ddeConsultPsys: any;
    entrInformels: any;
    ambulatories: any;
    hospitables: any;
    somatics: any;
    lodgings: any;
    socioPros: any;
    addForm = this.fb.group({
        id: null,
        visitor_id: [null, Validators.required],
        date: [null, Validators.required],
        interview_type: null,
        help_type_ids: null,
        consultation: null,
        accomp_procedure: null,
        home_hospital_visit: null,
        user_concertation: null,
        intervision: null,
        observations: null,
        asker_id: null,
        orientation_psy_lf: null,
        healthcare_sm_worry_notices: null,
        healthcare_sm_prison_care: null,
        vpe_healthcare_sm_ambulatory_ids: null,
        vpe_healthcare_sm_hospi_ids: null,
        vpe_hosting_ids: null,
        vpe_socio_professional_ids: null,
        vpe_somatic_ambulatory_ids: null
    });
    hasDataChoiceGroups = true;
    hasData = {
        visitorPsychoEntry: false,
        choiceGroups: {
            'dde_consult_psy': false,
            'entr_informel': false,
            'anc-dos-psy': false,
            'yes-no': false,
            'stopping_treatment': false,
            'ambulatory': false,
            'hospitable': false,
            'somatic': false,
            'lodging': false,
            'socio-pro': false
        }
    };

    @ViewChild('datePicker') datePicker;
    @ViewChild('ngSelectAddMultiple') ngSelectAddMultiple;

    constructor(private cdref: ChangeDetectorRef,
                private fb: UntypedFormBuilder,
                private miscService: MiscService,
                private choiceGroupService: ChoiceGroupService,
                private visitorService: VisitorService,
                private visitorPsychoEntryService: VisitorPsychoEntryService,
                public globals: Globals) {}

    ngOnInit() {
        this.pickerConfig = JSON.parse(JSON.stringify(this.globals.pickerConfig));
        this.cdref.detectChanges();
    }

    reset() {
        this.addForm.reset();
        this.visitorId = null;
        this.cud = "add";
    }

    addSideData(field, data, idName) {
        let arr = [];
        data.forEach(d => {
            arr.push(d[idName]);
        });
        this.addForm.get(field).patchValue(arr);
    }
    
    openModal(visitorId = null, visitorPsychoEntry = null) {
        if(this.miscService.checkEnvPermission("cudVisitorPsychoEntry")) {
            this.isLoading = true;
            let keys = {
                keys: [
                    "anc-dos-psy", "yes-no", "stopping_treatment", "dde_consult_psy", "entr_informel", "ambulatory", "hospitable",
                    "somatic", "lodging", "socio-pro"
                ]
            };
            this.choiceGroupService.getMultipleByKeys(keys).subscribe(data => {
                this.choiceGroups = data;
                this.setHasDataChoiceGroups();
                this.reset();
                this.visitorPsychoEntry = visitorPsychoEntry;
                this.hasData.visitorPsychoEntry = this.miscService.checkDataLength(this.visitorPsychoEntry);
                this.visitorId = visitorId;
                if(this.visitorId) {
                    this.addForm.get('visitor_id').patchValue(visitorId);
                    this.getForbiddenDates(true);
                    if(this.hasData.visitorPsychoEntry) {
                        if(this.miscService.checkDataLength(visitorPsychoEntry.vpe_healthcare_sm_ambulatories)) {
                            this.addSideData("vpe_healthcare_sm_ambulatory_ids", visitorPsychoEntry.vpe_healthcare_sm_ambulatories, "ambulatory_id");
                        }
                        if(this.miscService.checkDataLength(visitorPsychoEntry.vpe_healthcare_sm_hospis)) {
                            this.addSideData("vpe_healthcare_sm_hospi_ids", visitorPsychoEntry.vpe_healthcare_sm_hospis, "hospi_id");
                        }
                        if(this.miscService.checkDataLength(visitorPsychoEntry.vpe_hostings)) {
                            this.addSideData("vpe_hosting_ids", visitorPsychoEntry.vpe_hostings, "hosting_id");
                        }
                        if(this.miscService.checkDataLength(visitorPsychoEntry.vpe_socio_professionals)) {
                            this.addSideData("vpe_socio_professional_ids", visitorPsychoEntry.vpe_socio_professionals, "socio_professional_id");
                        }
                        if(this.miscService.checkDataLength(visitorPsychoEntry.vpe_somatic_ambulatories)) {
                            this.addSideData("vpe_somatic_ambulatory_ids", visitorPsychoEntry.vpe_somatic_ambulatories, "somatic_id");
                        }
                    }
                }
                else {
                    this.loadVisitors();
                }
                this.isLoading = false;
                $(this.modal).modal('show');
            });
        }
    }

    getForbiddenDates(setup = false) {
        let data = {
            visitor_id: this.addForm.value.visitor_id,
            visitor_psycho_entries_id: this.hasData.visitorPsychoEntry ? [this.visitorPsychoEntry.id] : null
        };
        this.visitorPsychoEntryService.getDatesByVisitor(data).subscribe(data => {
            this.forbiddenDates = data;
            if(setup) {
                this.resetDate();
            }
            else {
                this.checkDate(this.addForm.value.date, "date");
            }
            this.checkEdit();
        });
    }

    checkEdit() {
        if(this.hasData.visitorPsychoEntry) {
            this.cud = "edit";
            this.addForm.patchValue(this.visitorPsychoEntry);
            if(this.visitorPsychoEntry.date) {
                this.datePicker.setDate(this.visitorPsychoEntry.date);
            }
            if(this.miscService.checkDataLength(this.visitorPsychoEntry.vpeFkHelpTypes)) {
                this.addHelpTypes(this.visitorPsychoEntry.vpeFkHelpTypes);
            }
        }
        else {
            this.ngSelectAddMultiple.setValue();
        }
    }

    addHelpTypes(vpeFkHelpTypes) {
        let arr = [];
        vpeFkHelpTypes.forEach(vfht => {
            arr.push(vfht.help_type_id);
        });
        this.addForm.get('help_type_ids').patchValue(arr);
        this.ngSelectAddMultiple.setValue(arr);
    }

    setHasDataChoiceGroups() {
        this.hasDataChoiceGroups = true;
        this.choiceGroups.forEach(cg => {
            if(cg.choices.length && this.hasData.choiceGroups[cg.key] !== undefined) {
                this.hasData.choiceGroups[cg.key] = true;
                switch(cg.key) {
                    case "dde_consult_psy":
                        this.ddeConsultPsys = cg.choices;
                        break;
                    case "entr_informel":
                        this.entrInformels = cg.choices;
                        break;
                    case "ambulatory":
                        this.ambulatories = cg.choices;
                        break;
                    case "hospitable":
                        this.hospitables = cg.choices;
                        break;
                    case "somatic":
                        this.somatics = cg.choices;
                        break;
                    case "lodging":
                        this.lodgings = cg.choices;
                        break;
                    case "socio-pro":
                        this.socioPros = cg.choices;
                        break;
                }
            }
        });
        Object.keys(this.hasData.choiceGroups).forEach(key => {
            if(!this.hasData.choiceGroups[key]) {
                this.hasDataChoiceGroups = false;
            }
        });
    }

    goToAddVisitorUrl() {
        $(this.modal).modal('hide');
        this.miscService.goToUrlDelay(['/visitor/add']);
    }

    getChoiceGroup(key) {
        let choiceGroup = this.miscService.filterArray(this.choiceGroups, "key", key);

        return choiceGroup ? choiceGroup : null;
    }

    addChoices(items, choices) {
        this[items] = this.miscService.addChoices(items, choices);
    }

    changeChoice(field, value) {
        let val = this.miscService.getChoice(value);
        this.addForm.get(field).patchValue(val);
    }

    getChoice(value) {
        if(value) {
            if(value.constructor !== Array) {   // If object
                return value.id;
            }
            else {
                let arrIds = [];
                value.forEach(val => {
                    arrIds.push(val.id);
                });
                return arrIds;
            }
        }

        return null;
    }

    loadVisitors() {
        this.visitors = concat(
            of([]),
            this.visitorsInput.pipe(
                distinctUntilChanged(),
                tap(() => this.visitorsLoading = true),
                debounceTime(500),
                switchMap(term => this.visitorService.getAll({term: term, limit: 5, filter: true}).pipe(
                    catchError(() => of([])),
                    tap(() => this.visitorsLoading = false),
                ))
            )
        );
    }

    dateChanged(date, field) {
        if(date) {
            this.checkDate(date, field);
        }
        else {
            this.resetField(field);
        }
	}

    checkDate(date, field) {
        if(!this.miscService.checkDateInArray(date, this.forbiddenDates)) {
            this.addForm.get(field).patchValue(date);
            this.wrongDate = false;
        }
        else {
            this.miscService.showMsg({ msg: "DATE_ALREADY_USED_FOR_OTHER_PSYCHO_ENTRY_FOR_VISITOR", status: "warning", ignoreLastNotif: true });
            this.resetField(field);
            this.wrongDate = true;
        }
    }

    resetDate() {
        this.datePicker.resetDateNow();
    }

    resetField(field) {
        this.addForm.get(field).reset();
    }

    submit() {
        this.isSubmitting = true;
        let data = this.addForm.value;
        if(this.cud == "add") {
            this.create(data);
        }
        else {
            this.update(data);
        }
    }

    create(data) {
        this.visitorPsychoEntryService.create(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_CREATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPsychoEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_NOT_CREATED" });
        });
    }

    update(data) {
        this.visitorPsychoEntryService.update(data).subscribe(data => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_UPDATED", status: 'success' });
            $(this.modal).modal('hide');
            this.addPsychoEntryEvent.emit();
        }, error => {
            this.isSubmitting = false;
            this.miscService.showMsg({ msg: "VISITOR_PSYCHO_ENTRY_NOT_UPDATED" });
        });
    }
}
