<ng-container *ngxPermissionsOnly="'cudVisitorPsycho'">
    <button type="button"
            class="btn btn-success mb-3"
            (click)="utilVisitorPartsPsychoModalsAdd.openModal(visitor?.id, visitorPsycho)"
            *ngIf="!isLoading">
        <ng-container *ngIf="!hasData?.visitorPsycho">
            <i class="fa fa-plus"></i> {{ 'VISITORS_PSYCHO.CREATE_PSYCHO_DATA' | translate }}  
        </ng-container>
        <ng-container *ngIf="hasData?.visitorPsycho">
            <i class="fa fa-edit"></i> {{ 'VISITORS_PSYCHO.EDIT_PSYCHO_DATA' | translate }}  
        </ng-container>
    </button>
</ng-container>
<ng-container *ngIf="!isLoading">
    <ng-container *ngIf="hasData?.visitorPsycho">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS.GENERAL_INFORMATION' | translate }}</h5>
                    </div>
                    <div class="content">
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.ORIENTED_BY' | translate }} :</strong> {{ visitorPsycho?.oriented_by?.name | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.DISPARITION_PERSON' | translate }} :</strong> {{ visitorPsycho?.disparition_person | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.DEATH_PERSON' | translate }} :</strong> {{ visitorPsycho?.death_person | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.CONSULTATIONS' | translate }} :</strong> {{ visitorPsycho?.consultations | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.ASKER' | translate }} :</strong> {{ visitorPsycho?.asker?.name | ifEmpty: '-' }}
                        </p>
                        <p>
                            <strong>{{ 'VISITORS_PSYCHO.SOMATIC_ATCD_FOLLOW_UP' | translate }} :</strong> {{ visitorPsycho?.atcd_somatic_monitoring | ifEmpty: '-' }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS_PSYCHO.PREVIOUS_CONTACTS' | translate }}</h5>
                    </div>
                    <div class="content p-0">
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.ADDICTIONS' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.HOSPI' | translate }} :</strong> {{ visitorPsycho?.prior_addictions_hospi?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_addictions_hospi_remark">
                                        ({{ visitorPsycho?.prior_addictions_hospi_remark }})
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.AMBULATORY' | translate }} :</strong> {{ visitorPsycho?.prior_addictions_ambulatory?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_addictions_ambulatory_remark">
                                        ({{ visitorPsycho?.prior_addictions_ambulatory_remark }})
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.PSY' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.HOSPI' | translate }} :</strong> {{ visitorPsycho?.prior_psy_hospi?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_psy_hospi_remark">
                                        ({{ visitorPsycho?.prior_psy_hospi_remark }})
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.AMBULATORY' | translate }} :</strong> {{ visitorPsycho?.prior_psy_ambulatory?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_psy_ambulatory_remark">
                                        ({{ visitorPsycho?.prior_psy_ambulatory_remark }})
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.PATH_PSY_WITH_THETA_NECESSARY' | translate }} :</strong> {{ visitorPsycho?.prior_psy_path_theta?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_psy_path_theta_remark">
                                        ({{ visitorPsycho?.prior_psy_path_theta_remark }})
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.THETA_AT_ENTRY' | translate }} :</strong> {{ visitorPsycho?.prior_psy_theta_entry?.name | ifEmpty: '-' }}
                                    <ng-container *ngIf="visitorPsycho?.prior_psy_theta_entry_remark">
                                        ({{ visitorPsycho?.prior_psy_theta_entry_remark }})
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.IF_NOT_WHY' | translate }} :</strong> 
                                    <ng-container *ngIf="visitorPsycho?.visitor_psycho_fk_no_reasons?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let visitorPyschoFkNoReason of visitorPsycho?.visitor_psycho_fk_no_reasons">
                                                {{ visitorPyschoFkNoReason?.no_reason?.name }}<span class="separator-comma">, </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsycho?.visitor_psycho_fk_no_reasons?.length">
                                        -
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="header card-header-title">
                        <h5 class="title">{{ 'VISITORS_PSYCHO.REFERRALS' | translate }}</h5>
                    </div>
                    <div class="content p-0">
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.TO_SM_CARE' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.AMBULATORY' | translate }} :</strong> 
                                    <ng-container *ngIf="vpeHealthcareSmAmbulatories?.items?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vhsa of vpeHealthcareSmAmbulatories?.items">
                                                {{ vhsa?.name }} ({{ vhsa?.nb_entries }})<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!vpeHealthcareSmAmbulatories?.items?.length">
                                        -
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TOTAL_AMBULATORY' | translate }} :</strong> 
                                    {{ vpeHealthcareSmAmbulatories?.total }}
                                    <ng-container *ngIf="vpeHealthcareSmAmbulatories?.nb_entries">
                                        <ng-container *ngIf="vpeHealthcareSmAmbulatories?.nb_entries == 1">
                                         ({{ vpeHealthcareSmAmbulatories?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRY' | translate }})
                                        </ng-container>
                                        <ng-container *ngIf="vpeHealthcareSmAmbulatories?.nb_entries > 1">
                                         ({{ vpeHealthcareSmAmbulatories?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRIES' | translate }})
                                        </ng-container>
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.HOSPITALS' | translate }} :</strong> 
                                    <ng-container *ngIf="vpeHealthcareSmHospis?.items?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vhsh of vpeHealthcareSmHospis?.items">
                                                {{ vhsh?.name }} ({{ vhsh?.nb_entries }})<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!vpeHealthcareSmHospis?.items?.length">
                                        -
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TOTAL_HOSPITALS' | translate }} :</strong> 
                                    {{ vpeHealthcareSmHospis?.total }}
                                    <ng-container *ngIf="vpeHealthcareSmHospis?.nb_entries">
                                        <ng-container *ngIf="vpeHealthcareSmHospis?.nb_entries == 1">
                                         ({{ vpeHealthcareSmHospis?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRY' | translate }})
                                        </ng-container>
                                        <ng-container *ngIf="vpeHealthcareSmHospis?.nb_entries > 1">
                                         ({{ vpeHealthcareSmHospis?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRIES' | translate }})
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.TO_OUTPATIENT_SOMATIC_CARE' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TO_OUTPATIENT_SOMATIC_CARE' | translate }} :</strong> 
                                    <ng-container *ngIf="vpeSomaticAmbulatories?.items?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vsa of vpeSomaticAmbulatories?.items">
                                                {{ vsa?.name }} ({{ vsa?.nb_entries }})<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!vpeSomaticAmbulatories?.items?.length">
                                        -
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TOTAL_SOMATIC' | translate }} :</strong>
                                    {{ vpeSomaticAmbulatories?.total }}
                                    <ng-container *ngIf="vpeSomaticAmbulatories?.nb_entries">
                                        <ng-container *ngIf="vpeSomaticAmbulatories?.nb_entries == 1">
                                         ({{ vpeSomaticAmbulatories?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRY' | translate }})
                                        </ng-container>
                                        <ng-container *ngIf="vpeSomaticAmbulatories?.nb_entries > 1">
                                         ({{ vpeSomaticAmbulatories?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRIES' | translate }})
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.TO_HOSTING' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TO_HOSTING' | translate }} :</strong> 
                                    <ng-container *ngIf="vpeHostings?.items?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vh of vpeHostings?.items">
                                                {{ vh?.name }} ({{ vh?.nb_entries }})<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!vpeHostings?.items?.length">
                                        -
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TOTAL_HOSTING' | translate }} :</strong> 
                                    {{ vpeHostings?.total }}
                                    <ng-container *ngIf="vpeHostings?.nb_entries">
                                        <ng-container *ngIf="vpeHostings?.nb_entries == 1">
                                         ({{ vpeHostings?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRY' | translate }})
                                        </ng-container>
                                        <ng-container *ngIf="vpeHostings?.nb_entries > 1">
                                         ({{ vpeHostings?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRIES' | translate }})
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p class="m-0 p-3 subtitle">
                                <strong>{{ 'VISITORS_PSYCHO.SOCIO_PROFESSIONAL' | translate }}</strong>
                            </p>
                            <div class="pt-3 pb-3 pl-5">
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.SOCIO_PROFESSIONAL' | translate }} :</strong> 
                                    <ng-container *ngIf="vpeSocioProfessionals?.items?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vsp of vpeSocioProfessionals?.items">
                                                {{ vsp?.name }} ({{ vsp?.nb_entries }})<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!vpeSocioProfessionals?.items?.length">
                                        -
                                    </ng-container>
                                </p>
                                <p>
                                    <strong>{{ 'VISITORS_PSYCHO.TOTAL_SOCIO_PROFESSIONAL' | translate }} :</strong> 
                                    {{ vpeSocioProfessionals?.total }}
                                    <ng-container *ngIf="vpeSocioProfessionals?.nb_entries">
                                        <ng-container *ngIf="vpeSocioProfessionals?.nb_entries == 1">
                                         ({{ vpeSocioProfessionals?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRY' | translate }})
                                        </ng-container>
                                        <ng-container *ngIf="vpeSocioProfessionals?.nb_entries > 1">
                                         ({{ vpeSocioProfessionals?.nb_entries }} {{ 'VISITORS_PSYCHO.CONCERNED_ENTRIES' | translate }})
                                        </ng-container>
                                    </ng-container>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasData?.visitorPsycho">
        <p>{{ 'VISITORS_PSYCHO.NO_PSYCHO_DATA_FOUND' | translate }}</p>
    </ng-container>
</ng-container>
<ng-container *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
</ng-container>
<app-util-visitor-parts-psycho-file-modals-add
                            (addPsychoEvent)="getVisitorPsycho()" #utilVisitorPartsPsychoModalsAdd></app-util-visitor-parts-psycho-file-modals-add>