import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { MiscService, VisitorPsychoService } from '@services/index';

@Component({
    selector: 'app-util-visitor-parts-psycho-file',
    templateUrl: './file.component.html'
})
export class UtilVisitorPartsPsychoFileComponent implements OnInit {
    @Input() visitor: any;
    
    isLoading = false;
    visitorPsycho: any;
    visitorPsychoEntries: any;
    vpeHealthcareSmAmbulatories = {
        items: [],
        total: 0,
        nb_entries: 0
    };
    vpeHealthcareSmHospis = {
        items: [],
        total: 0,
        nb_entries: 0
    };
    vpeSomaticAmbulatories = {
        items: [],
        total: 0,
        nb_entries: 0
    };
    vpeHostings = {
        items: [],
        total: 0,
        nb_entries: 0
    };
    vpeSocioProfessionals = {
        items: [],
        total: 0,
        nb_entries: 0
    };
    hasData = {
        visitorPsycho: false,
        visitorPsychoEntries: false
    };

    constructor(private cdref: ChangeDetectorRef,
                private miscService: MiscService,
                private VisitorPsychoService: VisitorPsychoService) {
        this.miscService.setPageTitle("TITLES.VISITOR_PSYCHO_PSYCHOLOGICAL_FILE");
    }

    ngOnInit() {
        this.miscService.can("getVisitorPsycho");
        this.getVisitorPsycho();
        this.cdref.detectChanges();
    }

    getVisitorPsycho() {
        this.isLoading = true;
        this.VisitorPsychoService.getByVisitor(this.visitor.id).subscribe(data => {
            this.visitorPsycho = data.visitorPsycho;
            this.visitorPsychoEntries = data.visitorPsychoEntries;
            this.hasData.visitorPsycho = this.miscService.checkDataLength(this.visitorPsycho);
            this.hasData.visitorPsychoEntries = this.miscService.checkDataLength(this.visitorPsychoEntries);
            this.setVpeData();
            this.isLoading = false;
        });
    }

    setVpeData() {
        this.vpeHealthcareSmAmbulatories = {
            items: [],
            total: 0,
            nb_entries: 0
        };
        this.vpeHealthcareSmHospis = {
            items: [],
            total: 0,
            nb_entries: 0
        };
        this.vpeSomaticAmbulatories = {
            items: [],
            total: 0,
            nb_entries: 0
        };
        this.vpeHostings = {
            items: [],
            total: 0,
            nb_entries: 0
        };
        this.vpeSocioProfessionals = {
            items: [],
            total: 0,
            nb_entries: 0
        };
        if(this.hasData.visitorPsychoEntries) {
            this.visitorPsychoEntries.forEach(vpe => {
                if(this.miscService.checkDataLength(vpe.vpe_healthcare_sm_ambulatories)) {
                    vpe.vpe_healthcare_sm_ambulatories.forEach(vhsa => {
                        let index = this.miscService.filterArrayIndex(this.vpeHealthcareSmAmbulatories.items, "name", vhsa.ambulatory.name);
                        if(index == -1) {
                            this.vpeHealthcareSmAmbulatories.items.push({
                                name: vhsa.ambulatory.name,
                                nb_entries: 1
                            });
                        }
                        else {
                            this.vpeHealthcareSmAmbulatories.items[index].nb_entries++;
                        }
                        this.vpeHealthcareSmAmbulatories.total++;
                    });
                    this.vpeHealthcareSmAmbulatories.nb_entries++;
                }
                if(this.miscService.checkDataLength(vpe.vpe_healthcare_sm_hospis)) {
                    vpe.vpe_healthcare_sm_hospis.forEach(vhs => {
                        let index = this.miscService.filterArrayIndex(this.vpeHealthcareSmHospis.items, "name", vhs.hospi.name);
                        if(index == -1) {
                            this.vpeHealthcareSmHospis.items.push({
                                name: vhs.hospi.name,
                                nb_entries: 1
                            });
                        }
                        else {
                            this.vpeHealthcareSmHospis.items[index].nb_entries++;
                        }
                        this.vpeHealthcareSmHospis.total++;
                    });
                    this.vpeHealthcareSmHospis.nb_entries++;
                }
                if(this.miscService.checkDataLength(vpe.vpe_somatic_ambulatories)) {
                    vpe.vpe_somatic_ambulatories.forEach(vsa => {
                        let index = this.miscService.filterArrayIndex(this.vpeSomaticAmbulatories.items, "name", vsa.somatic.name);
                        if(index == -1) {
                            this.vpeSomaticAmbulatories.items.push({
                                name: vsa.somatic.name,
                                nb_entries: 1
                            });
                        }
                        else {
                            this.vpeSomaticAmbulatories.items[index].nb_entries++;
                        }
                        this.vpeSomaticAmbulatories.total++;
                    });
                    this.vpeSomaticAmbulatories.nb_entries++;
                }
                if(this.miscService.checkDataLength(vpe.vpe_hostings)) {
                    vpe.vpe_hostings.forEach(vh => {
                        let index = this.miscService.filterArrayIndex(this.vpeHostings.items, "name", vh.hosting.name);
                        if(index == -1) {
                            this.vpeHostings.items.push({
                                name: vh.hosting.name,
                                nb_entries: 1
                            });
                        }
                        else {
                            this.vpeHostings.items[index].nb_entries++;
                        }
                        this.vpeHostings.total++;
                    });
                    this.vpeHostings.nb_entries++;
                }
                if(this.miscService.checkDataLength(vpe.vpe_socio_professionals)) {
                    vpe.vpe_socio_professionals.forEach(vsp => {
                        let index = this.miscService.filterArrayIndex(this.vpeSocioProfessionals.items, "name", vsp.socio_professional.name);
                        if(index == -1) {
                            this.vpeSocioProfessionals.items.push({
                                name: vsp.socio_professional.name,
                                nb_entries: 1
                            });
                        }
                        else {
                            this.vpeSocioProfessionals.items[index].nb_entries++;
                        }
                        this.vpeSocioProfessionals.total++;
                    });
                    this.vpeSocioProfessionals.nb_entries++;
                }
            });
        }
    }

    getInterviewType(interviewType) {
        if(interviewType) {
            if(interviewType == "formal") {
                return this.miscService._t("VISITORS_PSYCHO.FORMAL");
            }
            else {
                return this.miscService._t("VISITORS_PSYCHO.INFORMAL");
            }
        }

        return "-";
    }
}
