<div class="row">
    <div class="col-md-12">
        <app-util-visitor-parts-psycho-entry-list-filter
                                                [selfVisitor]="selfVisitor"
                                                (filterEvent)="filterVisitorPsychoEntries($event)"></app-util-visitor-parts-psycho-entry-list-filter>
    </div>
</div>
<div class="row mt-5">
    <div class="col-md-12 align-items-center justify-content-space-between">
        <h5 class="title mb-3">
            <ng-container *ngIf="selfVisitor">
                {{ 'VISITORS_PSYCHO.VISITOR_ENTRIES' | translate }}
            </ng-container>
            <ng-container *ngIf="!selfVisitor">
                {{ 'VISITORS_PSYCHO.VISITORS_ENTRIES' | translate }}
            </ng-container>
        </h5>
        <ng-container *ngxPermissionsOnly="'cudVisitorPsychoEntry'">
            <button type="button"
                    class="btn btn-primary btn-fill"
                    (click)="utilVisitorPartsPsychoEntryModalsAdd.openModal(visitor?.id)"
                    *ngIf="manageEntries">
                <i class="fa fa-plus"></i> {{ 'VISITORS_PSYCHO.ADD_ENTRY' | translate }}
            </button>
        </ng-container>
    </div>
</div>
<div class="row mt-3">
    <div class="col-md-12">
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="hasData?.visitorPsychoEntries">
                <div class="overflow-auto pb-3">
                    <table class="table table-striped pb-4">
                        <thead>
                            <tr>
                                <th class="p-3 text-center-important">#</th>
                                <ng-container *ngxPermissionsOnly="'cudVisitorPsychoEntry'">
                                    <th class="p-3 text-center-important" *ngIf="manageEntries">
                                        {{ 'SHARED.ACTIONS' | translate }}
                                    </th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.DATE' | translate }}</th>
                                <ng-container *ngIf="!selfVisitor">
                                    <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.VISITOR' | translate }}</th>
                                </ng-container>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.INTERVIEW_TYPE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.HELP_TYPE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.ORIENTATION_PSY_LF' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.ASKER' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.CONSULTATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.ACCOMP_PROCEDURE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.HOME_HOSPITAL_VISIT' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.USER_CONCERTATION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.INTERVISION' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.OBSERVATIONS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.AMBULATORY' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.HOSPITALS' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.WORRY_NOTICES' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.HEALTHCARE_PRISON_CARE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.TO_OUTPATIENT_SOMATIC_CARE' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.TO_HOSTING' | translate }}</th>
                                <th class="p-3 text-center-important">{{ 'VISITORS_PSYCHO.SOCIO_PROFESSIONAL' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let visitorPsychoEntry of visitorPsychoEntries?.data; let i = index">
                                <td class="p-3 text-center-important">{{ i + 1 }}</td>
                                <ng-container *ngxPermissionsOnly="'cudVisitorPsychoEntry'">
                                    <ng-container *ngIf="manageEntries">
                                        <td class="p-3 text-center-important td-actions" >
                                            <button type="button"
                                                    class="btn btn-success btn-simple p-0"
                                                    title="{{ 'VISITORS_PSYCHO.EDIT_ENTRY' | translate }}"
                                                    (click)="utilVisitorPartsPsychoEntryModalsAdd.openModal(visitor?.id, visitorPsychoEntry)"
                                                    appTooltip>
                                                <i class="fa fa-edit"></i>
                                            </button>
                                            <button type="button"
                                                    class="btn btn-danger btn-simple ml-3 p-0"
                                                    title="{{ 'VISITORS_PSYCHO.DELETE_ENTRY' | translate }}"
                                                    (click)="delete(visitorPsychoEntry)"
                                                    appTooltip>
                                                <i class="fa fa-trash"></i>
                                            </button>
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <td class="p-3 text-center-important">{{ visitorPsychoEntry?.date | localizedDate: true | ifEmpty: '-' }}</td>
                                <ng-container *ngIf="!selfVisitor">
                                    <td class="p-3 text-center-important">
                                        <a [routerLink]="['/visitor', visitorPsychoEntry?.vId, 'psycho']"
                                            class="text-primary">
                                            {{ visitorPsychoEntry?.showName }}
                                        </a>
                                    </td>
                                </ng-container>
                                <td class="p-3 text-center-important">
                                    {{ getInterviewType(visitorPsychoEntry?.interview_type) }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpeFkHelpTypes?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vpeFkHelpType of visitorPsychoEntry?.vpeFkHelpTypes">
                                                {{ vpeFkHelpType?.name }}<span class="separator-comma">, </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpeFkHelpTypes?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorPsychoEntry?.orientation_psy_lf | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorPsychoEntry?.askerName | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorPsychoEntry?.consultation"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorPsychoEntry?.accomp_procedure"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorPsychoEntry?.home_hospital_visit"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-icon-check-close [value]="visitorPsychoEntry?.user_concertation"></app-icon-check-close>
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorPsychoEntry?.intervision | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <app-text-limit [text]="visitorPsychoEntry?.observations" [title]="'OBSERVATIONS'"></app-text-limit>
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpe_healthcare_sm_ambulatories?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vhsa of visitorPsychoEntry?.vpe_healthcare_sm_ambulatories">
                                                {{ vhsa?.ambulatory?.name }}<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpe_healthcare_sm_ambulatories?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpe_healthcare_sm_hospis?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vhsh of visitorPsychoEntry?.vpe_healthcare_sm_hospis">
                                                {{ vhsh?.hospi?.name }}<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpe_healthcare_sm_hospis?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorPsychoEntry?.healthcare_sm_worry_notices | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    {{ visitorPsychoEntry?.healthcare_sm_prison_care | ifEmpty: '-' }}
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpe_somatic_ambulatories?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vsa of visitorPsychoEntry?.vpe_somatic_ambulatories">
                                                {{ vsa?.somatic?.name }}<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpe_somatic_ambulatories?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpe_hostings?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vh of visitorPsychoEntry?.vpe_hostings">
                                                {{ vh?.hosting?.name }}<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpe_hostings?.length">
                                        -
                                    </ng-container>
                                </td>
                                <td class="p-3 text-center-important">
                                    <ng-container *ngIf="visitorPsychoEntry?.vpe_socio_professionals?.length">
                                        <span class="list-comma">
                                            <span *ngFor="let vsp of visitorPsychoEntry?.vpe_socio_professionals">
                                                {{ vsp?.socio_professional?.name }}<span class="separator-comma">; </span>
                                            </span>
                                        </span>
                                    </ng-container>
                                    <ng-container *ngIf="!visitorPsychoEntry?.vpe_socio_professionals?.length">
                                        -
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="align-items-center justify-content-center" *ngIf="visitorPsychoEntries?.last_page > 1">
                        <app-pagination [data]="visitorPsychoEntries" (switchPage)="switchPage($event)"></app-pagination>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!hasData?.visitorPsychoEntries">
                <p>
                    {{ 'VISITORS_PSYCHO.NO_VISITOR_ENTRY_FOUND' | translate }}
                </p>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <p>
                <app-loading-spinner></app-loading-spinner>
            </p>
        </ng-container>
    </div>
</div>
<app-util-visitor-parts-psycho-entry-modals-add
                                    (addPsychoEntryEvent)="addPsychoEntry()" #utilVisitorPartsPsychoEntryModalsAdd></app-util-visitor-parts-psycho-entry-modals-add>